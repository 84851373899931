import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from '@/components/Button';
import { navigate } from 'gatsby-link';
import * as styles from './notfound.module.scss';

import notFound from '@/assets/images/notfound.svg';

const NotFound = () => {
  const handleClick = () => navigate('/');

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center text-center">
      <div className={styles.container}>
        <img src={notFound} alt="página não encontrada" loading="lazy" />

        <div className={styles.infos}>
          <h2>Página não encontrada :(</h2>
          <p>Desculpe, não conseguimos encontrar a página que você estava procurando.</p>

          <Button
            text="Ir para a página inicial"
            variant="primary"
            onClick={handleClick}
            className={styles.button}
          />
        </div>
      </div>
    </Container>
  );
};
export default NotFound;
