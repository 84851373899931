import React from 'react';
import { Seo } from '@/components';
import Hero from '@/layouts/NotFound';
import Root from '@/layouts/Root/Root';

const NotFound = () => {
  return (
    <>
      <Seo title="Página não encontrada" />
      <Root variant="light" hero={<Hero />} />
    </>
  );
};

export default NotFound;
